
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    MultiSelect,
  },
  props: ["data"],
  setup(props, { emit }) {
    const orderTypes: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.ORDERTYPE_LIST);
    const orderTypeList = computed(
      () => store.state.OrderTypeModule.orderType.value || []
    );

    const onChange = (args) => {
      emit("changeOrderType", args);
    };
    return { orderTypes, store, orderTypeList, onChange };
  },
};
